var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap js-operation-details" }, [
    _c("div", { staticClass: "js-common-head" }, [
      _c(
        "div",
        [
          _c("el-button", {
            staticClass: "back-btn",
            attrs: {
              icon: "el-icon-arrow-left",
              size: "small",
              type: "primary",
              plain: "",
            },
            on: { click: _vm.$goBack },
          }),
          _c("label", { staticClass: "label" }, [_vm._v("任务详情报告")]),
          _c(
            "el-badge",
            { staticClass: "item", attrs: { value: "new" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  on: { click: _vm.showRating },
                },
                [_vm._v("评分")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { ref: "detailsContent", staticClass: "js-common-content" },
      [
        _c("div", { staticClass: "details-box details-box4" }, [
          _c("div", { staticClass: "details-box-tlt" }, [_vm._v("事件报告")]),
          _c(
            "div",
            { staticClass: "time-line-box" },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.eventList, function (currentevent) {
                  return _c("el-timeline-item", { key: currentevent.id }, [
                    _c("div", { staticClass: "time-line-tlt" }, [
                      _c("label", [
                        _vm._v("名称：" + _vm._s(currentevent.name)),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c("label", [
                        _vm._v("内容：" + _vm._s(currentevent.content)),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c(
                        "label",
                        [
                          _vm._v("评分："),
                          _vm._l(_vm.gradeList, function (rate, index) {
                            return _c("span", { key: index }, [
                              _vm._v(
                                _vm._s(
                                  `${rate.raterUserName}给${rate.rateeUserName}评分为${rate.grade}；`
                                )
                              ),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c("label", [
                        _vm._v("录入人：" + _vm._s(currentevent.createdByName)),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c("label", [
                        _vm._v("发生时间：" + _vm._s(currentevent.happenTime)),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c("label", [
                        _vm._v("备注：" + _vm._s(currentevent.remark)),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c("label", [
                        _vm._v(
                          "上报人：" + _vm._s(currentevent.reportUserName)
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c("label", [
                        _vm._v(
                          "紧急程度排名：" + _vm._s(currentevent.urgencyRank)
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c("p", { staticClass: "time-line-name" }, [
                        _vm._v("附件列表："),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-line-detail" }, [
                      _c(
                        "div",
                        { staticClass: "img-box" },
                        _vm._l(currentevent.attachList, function (att, d) {
                          return _c(
                            "li",
                            { key: d },
                            [
                              att.type == 1
                                ? _c("Xgplayer", {
                                    attrs: {
                                      config: {
                                        id: "watermelon1",
                                        url: att.fullUrl,
                                      },
                                    },
                                    on: {
                                      player: function ($event) {
                                        _vm.Player = $event
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c("div", {
                                attrs: { id: "watermelon-player-1" },
                              }),
                              att.type == 0
                                ? _c("img", {
                                    staticClass: "img",
                                    attrs: { src: att.fullUrl },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "details-box details-box2" }, [
          _c("div", { staticClass: "details-box-tlt" }, [_vm._v("主任务详情")]),
          _c("div", { staticClass: "subInfo" }, [
            _c("div", { staticClass: "item" }, [
              _c("label", [_vm._v("名称")]),
              _c("p", [_vm._v(_vm._s(_vm.currentMainTask.name))]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("label", [_vm._v("相关事件数")]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.currentMainTask.taskEventIds
                        ? _vm.currentMainTask.taskEventIds.length
                        : 0
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("label", [_vm._v("子任务数")]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.currentMainTask.taskEventIds
                        ? _vm.currentMainTask.subtaskIds.length
                        : 0
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "details-box details-box4" }, [
          _c("div", { staticClass: "details-box-tlt" }, [_vm._v("子任务报告")]),
          _c(
            "div",
            { staticClass: "time-line-box" },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.subtaskList, function (item, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index },
                    [
                      _c("div", { staticClass: "time-line-tlt" }, [
                        _c("label", [
                          _vm._v(_vm._s(index + 1) + ". " + _vm._s(item.name)),
                        ]),
                      ]),
                      _c("div", { staticClass: "time-line-detail" }, [
                        _c("p", { staticClass: "time-line-name" }, [
                          _vm._v("子任务内容: " + _vm._s(item.content)),
                        ]),
                      ]),
                      _c("div", { staticClass: "time-line-detail" }, [
                        _c("p", { staticClass: "time-line-name" }, [
                          _vm._v("备注: " + _vm._s(item.remark)),
                        ]),
                      ]),
                      _c("div", { staticClass: "time-line-detail" }, [
                        _c("p", { staticClass: "time-line-name" }, [
                          _vm._v("计划开始时间: " + _vm._s(item.planStart)),
                        ]),
                        _c("p", { staticClass: "time-line-name" }, [
                          _vm._v("计划结束时间: " + _vm._s(item.planEnd)),
                        ]),
                      ]),
                      _c("div", { staticClass: "time-line-detail" }, [
                        _c("p", { staticClass: "time-line-name" }, [
                          _vm._v("录像类型: " + _vm._s(item.recordingType)),
                        ]),
                      ]),
                      _c("div", { staticClass: "time-line-detail" }, [
                        _c("p", { staticClass: "time-line-name" }, [
                          _vm._v("附件列表："),
                        ]),
                        _c("ul", [
                          _c(
                            "div",
                            { staticClass: "img-box" },
                            _vm._l(item.attachList, function (att, d) {
                              return _c(
                                "li",
                                { key: d },
                                [
                                  att.type == 1
                                    ? _c("Xgplayer", {
                                        attrs: {
                                          config: {
                                            id: "watermelon2",
                                            url: att.fullUrl,
                                          },
                                        },
                                        on: {
                                          player: function ($event) {
                                            _vm.Player = $event
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c("div", {
                                    attrs: { id: "watermelon-player-2" },
                                  }),
                                  att.type == 0
                                    ? _c("img", {
                                        staticClass: "img",
                                        attrs: { src: att.fullUrl },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _vm._l(item.executorList, function (executor, ind) {
                        return _c(
                          "div",
                          { key: ind, staticClass: "time-line-detail" },
                          [
                            _c("p", { staticClass: "time-line-name" }, [
                              _vm._v("执行人: " + _vm._s(executor.userName)),
                            ]),
                            _c("p", { staticClass: "time-line-name" }, [
                              _vm._v(
                                " 执行人总执行时间：" +
                                  _vm._s(executor.executeStart) +
                                  " - " +
                                  _vm._s(executor.executeEnd) +
                                  " "
                              ),
                            ]),
                            _vm._l(
                              executor.recordingList,
                              function (recording, reind) {
                                return _c(
                                  "div",
                                  { key: reind, staticClass: "time-line-name" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSrc(
                                              recording.fullVideoUrl
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "执行记录 " +
                                            _vm._s(reind + 1) +
                                            " " +
                                            _vm._s(recording.cloudRecordStart) +
                                            " " +
                                            _vm._s(recording.cloudRecordEnd)
                                        ),
                                      ]
                                    ),
                                    _c("AddTags", {
                                      attrs: {
                                        id: recording.id,
                                        type: "task",
                                        "show-tags": _vm.convert(
                                          recording.tags
                                        ),
                                      },
                                      on: { "after-edit": _vm.getDetails },
                                    }),
                                    recording.fullVideoUrl != null
                                      ? _c("Xgplayer", {
                                          attrs: {
                                            config: {
                                              id: "watermelon3",
                                              url: recording.fullVideoUrl,
                                            },
                                          },
                                          on: {
                                            player: function ($event) {
                                              _vm.Player = $event
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    recording.fullImgUrls != null
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            recording.fullImgUrls,
                                            function (eximg, eximgid) {
                                              return _c(
                                                "li",
                                                { key: eximgid },
                                                [
                                                  _c("img", {
                                                    staticClass: "img",
                                                    attrs: { src: eximg },
                                                  }),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("Rating", {
          attrs: { type: "task" },
          model: {
            value: _vm.show.rating,
            callback: function ($$v) {
              _vm.$set(_vm.show, "rating", $$v)
            },
            expression: "show.rating",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }