<template>
  <div class="js-common-wrap js-operation-details">
    <div class="js-common-head">
      <div>
        <el-button
          class="back-btn"
          @click="$goBack"
          icon="el-icon-arrow-left"
          size="small"
          type="primary"
          plain
        ></el-button>
        <label class="label">任务详情报告</label>
        <el-badge value="new" class="item">
          <el-button style="margin-left: 20px" @click="showRating"
            >评分</el-button
          >
        </el-badge>
      </div>
    </div>
    <div class="js-common-content" ref="detailsContent">
      <div class="details-box details-box4">
        <div class="details-box-tlt">事件报告</div>
        <div class="time-line-box">
          <el-timeline>
            <el-timeline-item
              v-for="currentevent in eventList"
              :key="currentevent.id"
            >
              <div class="time-line-tlt">
                <label>名称：{{ currentevent.name }}</label>
              </div>
              <div class="time-line-detail">
                <label>内容：{{ currentevent.content }}</label>
              </div>
              <div class="time-line-detail">
                <label
                  >评分：<span
                    v-for="(rate, index) in gradeList"
                    :key="index"
                    >{{
                      `${rate.raterUserName}给${rate.rateeUserName}评分为${rate.grade}；`
                    }}</span
                  ></label
                >
              </div>
              <div class="time-line-detail">
                <label>录入人：{{ currentevent.createdByName }}</label>
              </div>
              <div class="time-line-detail">
                <label>发生时间：{{ currentevent.happenTime }}</label>
              </div>
              <div class="time-line-detail">
                <label>备注：{{ currentevent.remark }}</label>
              </div>
              <div class="time-line-detail">
                <label>上报人：{{ currentevent.reportUserName }}</label>
              </div>
              <div class="time-line-detail">
                <label>紧急程度排名：{{ currentevent.urgencyRank }}</label>
              </div>
              <div class="time-line-detail">
                <p class="time-line-name">附件列表：</p>
              </div>
              <div class="time-line-detail">
                <div class="img-box">
                  <li v-for="(att, d) in currentevent.attachList" :key="d">
                    <!--- <label  v-if="att.type==1" @click="handleSrc(att.fullUrl)">{{att.name}}</label> -->
                    <!-- <video-player
                      v-if="att.type == 1"
                      class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options="eventPlayerOptions[d]"
                      poster="@/assets/images/9.png"
                    ></video-player> -->
                    <Xgplayer
                      v-if="att.type == 1"
                      :config="{ id: 'watermelon1', url: att.fullUrl }"
                      @player="Player = $event"
                    />
                    <div id="watermelon-player-1"></div>
                    <img class="img" v-if="att.type == 0" :src="att.fullUrl" />
                  </li>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div class="details-box details-box2">
        <div class="details-box-tlt">主任务详情</div>
        <div class="subInfo">
          <div class="item">
            <label>名称</label>
            <p>{{ currentMainTask.name }}</p>
          </div>
          <div class="item">
            <label>相关事件数</label>
            <p>
              {{
                currentMainTask.taskEventIds
                  ? currentMainTask.taskEventIds.length
                  : 0
              }}
            </p>
          </div>
          <div class="item">
            <label>子任务数</label>
            <p>
              {{
                currentMainTask.taskEventIds
                  ? currentMainTask.subtaskIds.length
                  : 0
              }}
            </p>
          </div>
          <!--<div class="item"><label>是否中断</label><p>{{ currentMainTask.interruptFlag}}</p></div>-->
        </div>
      </div>
      <div class="details-box details-box4">
        <div class="details-box-tlt">子任务报告</div>
        <div class="time-line-box">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in subtaskList" :key="index">
              <div class="time-line-tlt">
                <label>{{ index + 1 }}. {{ item.name }}</label>
              </div>
              <div class="time-line-detail">
                <p class="time-line-name">子任务内容: {{ item.content }}</p>
              </div>
              <div class="time-line-detail">
                <p class="time-line-name">备注: {{ item.remark }}</p>
              </div>
              <div class="time-line-detail">
                <p class="time-line-name">计划开始时间: {{ item.planStart }}</p>
                <p class="time-line-name">计划结束时间: {{ item.planEnd }}</p>
              </div>
              <div class="time-line-detail">
                <p class="time-line-name">录像类型: {{ item.recordingType }}</p>
              </div>
              <div class="time-line-detail">
                <p class="time-line-name">附件列表：</p>
                <ul>
                  <div class="img-box">
                    <li v-for="(att, d) in item.attachList" :key="d">
                      <!-- <label  v-if="att.type==1" @click="handleSrc(att.fullUrl)">{{att.name}}</label> -->
                      <!-- <video-player
                        v-if="att.type == 1"
                        class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="subtaskPlayerOptions[d]"
                        poster="@/assets/images/9.png"
                      ></video-player> -->
                      <Xgplayer
                        v-if="att.type == 1"
                        :config="{
                          id: 'watermelon2',
                          url: att.fullUrl,
                        }"
                        @player="Player = $event"
                      />

                      <div id="watermelon-player-2"></div>

                      <img
                        class="img"
                        v-if="att.type == 0"
                        :src="att.fullUrl"
                      />
                    </li>
                  </div>
                </ul>
              </div>
              <div
                class="time-line-detail"
                v-for="(executor, ind) in item.executorList"
                :key="ind"
              >
                <p class="time-line-name">执行人: {{ executor.userName }}</p>
                <p class="time-line-name">
                  执行人总执行时间：{{ executor.executeStart }} -
                  {{ executor.executeEnd }}
                </p>
                <div
                  class="time-line-name"
                  v-for="(recording, reind) in executor.recordingList"
                  :key="reind"
                >
                  <label @click="handleSrc(recording.fullVideoUrl)"
                    >执行记录 {{ reind + 1 }} {{ recording.cloudRecordStart }}
                    {{ recording.cloudRecordEnd }}</label
                  >

                  <!-- <video-player
                    v-if="recording.fullVideoUrl != null"
                    class="video-player vjs-custom-skin"
                    ref="videoPlayer"
                    :playsinline="true"
                    :options="executorPlayerOptions[reind]"
                    poster="@/assets/images/9.png"
                  ></video-player> -->
                  <AddTags
                    :id="recording.id"
                    type="task"
                    :show-tags="convert(recording.tags)"
                    @after-edit="getDetails"
                  />
                  <Xgplayer
                    v-if="recording.fullVideoUrl != null"
                    :config="{ id: 'watermelon3', url: recording.fullVideoUrl }"
                    @player="Player = $event"
                  />
                  <div v-if="recording.fullImgUrls != null">
                    <li
                      v-for="(eximg, eximgid) in recording.fullImgUrls"
                      :key="eximgid"
                    >
                      <img class="img" :src="eximg" />
                    </li>
                  </div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <Rating v-model="show.rating" type="task" />
    </div>
  </div>
</template>
<script>
import Rating from "@/components/Rating";
import Xgplayer from "xgplayer-vue";
import AddTags from "@/components/AddTags";

export default {
  name: "",
  components: {
    Xgplayer,
    Rating,
    AddTags,
  },
  data() {
    return {
      show: { rating: false },
      type: 1,
      gradeList: [],
      videoListFlag: false,
      attachDialogVisible: false,
      currentID: "",
      detailsData: {},
      currentBarInfo: {
        content: "",
        useTime: 0,
        startDate: "",
        endDate: "",
      },
      reportInfo: {},
      eventDisplayType: 0,
      subtaskList: [],
      currentMainTask: "",
      eventList: [],
      player: {},
    };
  },
  mounted() {
    this.currentID = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    showRating() {
      this.show.rating = true;
    },
    //get maintask information
    getDetails() {
      //get maintask report
      this.$axios({
        method: "get",
        url: `api/csp/task/v1/task/report/${this.currentID}`,
        params: {
          taskId: this.currentID,
        },
      }).then((res) => {
        const data = res.data;
        if (!data.status) {
          this.detailsData = data.data;
          this.eventList = this.detailsData.taskEventList;
          this.subtaskList = this.detailsData.subtaskList;
          this.gradeList = data.data.gradeList;
        }
      });

      //get maintask data
      this.$axios({
        method: "get",
        url: `api/csp/task/v1/task/${this.currentID}`,
        params: {
          id: this.currentID,
        },
      }).then((res) => {
        const data = res.data;
        if (!data.status) {
          this.currentMainTask = data.data;
        }
      });
    },
    convert(str) {
      return str ? str.split(",") : [];
    },
    //click video name to switch video url
    handleSrc(url) {
      console.log(url);
      this.playerOptions.sources.src = url;
      this.playerOptions.sources = [
        {
          src: url,
          type: "video/mp4",
        },
      ];
      console.log(this.playerOptions);
    },

    handleAllAttach() {
      attachDialogVisible = true;
    },

    getReport() {
      this.$axios({
        method: "get",
        url: `api/csp/sop/v1/sopRecord/report/${this.currentID}`,
      }).then((res) => {
        const data = res.data;

        if (!data.status) {
          this.reportInfo = data.data;
          if (this.reportInfo.logoUrl) {
            this.img2base64(this.reportInfo.logoUrl, "logoUrl");
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/operationDetails.scss";
</style>
<style lang="scss">
.add-tags-root {
  button {
    margin: 0 !important;
  }
}
</style>
